import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import BlockContent from "../components/blockcontent"
import BlockGallery from "../components/blockgallery"
import Seo from "../components/seo"
import { featured } from "../utils/image"
import t from "../utils/translate"

import styles from "./about.module.css"

export const query = graphql`
  query($id: String!) {
    sanityPage(id: { eq: $id }) {
      ...CommonPage
      _rawSecondcontent
      gallery {
        asset {
          _id
        }
        nameandsurname
        personalrole
      }
    }
  }
`

const AboutTemplate = ({
  data: { sanityPage: page },
  pageContext: { locale, strings },
}) => (
  <Layout locale={locale}>
    <Seo locale={locale} seofield={page.seo}></Seo>
    <div className={styles.aboutContainer}>
      <h1 className={styles.h1}>{t(page.title, locale)}</h1>
      <div className={styles.twoColsContainer}>
        <img
          srcSet={`${featured(page.image, 400)} 360w, ${featured(page.image, 632)} 592w, ${featured(page.image, 807)} 767w, ${featured(page.image, 1022)} 992w`}
          src={featured(page.image)} alt={page.title} />

        {t(page._rawContent, locale) && (
          <BlockContent blocks={t(page._rawContent, locale)}></BlockContent>
        )}
      </div>
      <div>
        <h2 className={styles.h2}>{t(strings.vision, locale)}</h2>
        <div className={styles.twoParagraphsContainer}>
          {t(page._rawSecondcontent, locale) && (
            <BlockContent blocks={t(page._rawSecondcontent, locale)}></BlockContent>
          )}
        </div>
      </div>
      {page.gallery && (
        <div>
          <h2 className={styles.h2}>{t(strings.contributors, locale)}</h2>
          <BlockGallery gallery={page.gallery} locale={locale}></BlockGallery>
        </div>
      )}
    </div>
  </Layout>
)

export default AboutTemplate
