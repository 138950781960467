import React from "react"
import { list } from "../utils/image"

import styles from "./blockgallery.module.css"

const BlockGallery = ({ gallery, locale }) => (
  <div className={styles.galleryContainer}>
    {gallery && gallery.map((item, index) => (
      <a
        href={item.urlOfLink ? item.urlOfLink : "#"}
        target={item.isExternal ? "_blank" : "_self"}
        key={index}
      >
        <img src={list(item, 500)} alt={item.nameandsurname} />
        <h3 className={styles.h3Gallery}>{item.nameandsurname}</h3>
        <h4 className={styles.h4Gallery}>{(locale === "it" ? item.personalrole.split('|')[0] : item.personalrole.split('|')[1])}</h4>
      </a>
    ))}
  </div>
)

export default BlockGallery
